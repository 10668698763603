import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import ClipLoader from "react-spinners/ClipLoader"
import superagent from "superagent"

import Alert from "../components/Alert"
import InputField from "../components/InputField"
import TextArea from "../components/TextArea"
import Button from "../components/Button"
import ExternalLink from "../components/ExternalLink"

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  message: Yup.string().required("Required"),
})

const StyledErrorMessage = styled.div`
  font-size: 0.875rem;
  color: #cc0000;
  margin-top: 0.25rem;
`

const ErrorMessage = ({ error, touched }) => (
  <StyledErrorMessage>{error && touched ? error : null}</StyledErrorMessage>
)

const HtmlButton = ({ children, isLightBackground, ...props }) => (
  <button {...props}>{children}</button>
)

const submitUrl =
  "https://5lmtlwagm4.execute-api.us-east-1.amazonaws.com/Prod/message"

const onSubmit = (values, actions) => {
  superagent
    .post(submitUrl)
    .send(values)
    .then(() => {
      actions.setStatus("success")
    })
    .catch(() => {
      actions.setStatus("error")
      actions.setSubmitting(false)
    })
}

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneHref
        }
      }
    }
  `)
  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, handleSubmit, status }) => (
        <Form onSubmit={handleSubmit}>
          {status !== "success" && (
            <>
              <InputField
                name="name"
                placeholder="Name*"
                touched={touched.name}
                error={errors.name}
                disabled={isSubmitting}
                firstRow
                aria-label="Name"
                aria-required="true"
                aria-invalid={!!(touched.name && errors.name)}
              />
              <ErrorMessage error={errors.name} touched={touched.name} />
              <InputField
                name="email"
                placeholder="Email*"
                touched={touched.email}
                error={errors.email}
                disabled={isSubmitting}
                aria-label="Email"
                aria-required="true"
                aria-invalid={!!(touched.email && errors.email)}
              />
              <ErrorMessage error={errors.email} touched={touched.email} />
              <TextArea
                component="textarea"
                name="message"
                placeholder="How can we help you?"
                disabled={isSubmitting}
                aria-label="How can we help you?"
                aria-required="true"
                aria-invalid="false"
              />
              <ErrorMessage error={errors.message} touched={touched.message} />
              <Button
                as={HtmlButton}
                type="submit"
                isLightBackground
                disabled={isSubmitting}
                aria-label="Submit"
              >
                {isSubmitting ? (
                  <ClipLoader sizeUnit="rem" size={2} color="white" loading />
                ) : (
                  "Submit"
                )}
              </Button>
            </>
          )}
          {status === "success" && (
            <Alert variant="success">
              Thanks for your message! We'll email you a response shortly. Feel
              free to also contact us at{" "}
              <ExternalLink href={data.site.siteMetadata.phoneHref}>
                {data.site.siteMetadata.phone}
              </ExternalLink>{" "}
              if you have any questions.
            </Alert>
          )}
          {status === "error" && (
            <Alert variant="error">
              Something went wrong submitting your request. Please try again or
              call us at{" "}
              <ExternalLink href={data.site.siteMetadata.phoneHref}>
                {data.site.siteMetadata.phone}
              </ExternalLink>
              .
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
