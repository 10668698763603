import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import styled from "styled-components"

import { TertiaryHeading, PrimaryHeading } from "../components/typography"
import { Layout, Section } from "../components/layout"
import Seo from "../components/Seo"
import siteTheme from "../constants/siteTheme"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"
import Hero from "../components/Hero"
import Button from "../components/Button"
import ExternalLink from "../components/ExternalLink"
import ContactForm from "../components/ContactForm"

const ContactCol = styled(Col)`
  margin-top: 2rem;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    margin-top: 0;
  }
`

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneHref
          email
        }
      }
      mascot: file(relativePath: { eq: "compressed/dr-squeegee-mascot.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={siteTheme}>
      <Layout>
        <Seo
          title="Dr. Squeegee Window Cleaning: Contact Us"
          description="Call, email, or get a free estimate. We're Edmonton's best window cleaners. Our services include window, chandelier, gutter, and pressure cleaning."
        />
        <Hero imageUrl={heroBackgroundImage}>
          <Grid>
            <PrimaryHeading className="centered">Contact Us</PrimaryHeading>
          </Grid>
        </Hero>
        <Grid>
          <Section>
            <Row>
              <Col xs={12} md={8} mdOffset={2}>
                <Row>
                  <Col xs={12} sm={6}>
                    <TertiaryHeading>Owner</TertiaryHeading>
                    <div>Greg Mapplebeck</div>
                    <TertiaryHeading>Address</TertiaryHeading>
                    <div>101 Boxwood Bend</div>
                    <div>Fort Saskatchewan, AB</div>
                    <div>T8L 0B8</div>
                    <TertiaryHeading>Phone</TertiaryHeading>
                    <ExternalLink href={data.site.siteMetadata.phoneHref}>
                      {data.site.siteMetadata.phone}
                    </ExternalLink>
                    <div>
                      <Button to="/estimate" isLightBackground>
                        Get an Estimate
                      </Button>
                    </div>
                  </Col>
                  <ContactCol xs={12} sm={6}>
                    <TertiaryHeading>Send us a Message</TertiaryHeading>
                    <ContactForm />
                  </ContactCol>
                </Row>
              </Col>
            </Row>
          </Section>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default ContactPage
